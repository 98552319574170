import { NavigationService } from 'Services';
//eslint-disable-next-line
const environment = process.env.NODE_ENV || 'development';

const API_SERVER = () => {
  if (NavigationService.getStoreState) {
    const {
      application: { country }
    } = NavigationService.getStoreState();
    return countryBasedEnvironmentConfig[environment][country];
  } else {
    return countryBasedEnvironmentConfig[environment].default;
  }
};

// DO NOT CHANGE THIS TO TRUE unless you want to redirect all the users to agent V1
const redirectToAgentV1 = false;

const countryBasedEnvironmentConfig = {
  development: {
    default: 'https://staging.databyte.live',
    be: 'https://staging.databyte.live',
    fr: 'https://staging.databyte.live',
    ie: 'https://staging.databyte.live',
    uk: 'https://staging.databyte.live'
  },
  staging: {
    // env used by internal testing
    default: 'https://staging.databyte.live',
    be: 'https://staging.databyte.live',
    fr: 'https://staging.databyte.live',
    ie: 'https://staging.databyte.live',
    uk: 'https://staging.databyte.live'
  },
  test: {
    // env is used by client testing
    default: 'https://test.databyte.live',
    be: 'https://test.databyte.live',
    fr: 'https://test.databyte.live',
    ie: 'https://test.databyte.live',
    uk: 'https://test.databyte.live'
  },
  production: {
    default: 'https://databyte.live',
    be: 'https://databyte.live',
    fr: 'https://databyte.live',
    uk: 'https://uk.databyte.live',
    ie: 'https://uk.databyte.live'
  }
};

const environementConfig = {
  development: {
    CLIENT_LOGO_ENDPOINT: 'https://d1a3el6wjv3s6k.cloudfront.net/',
    PASSPHRASE: 'a9k~g>4BD~vQC6.x'
  },
  staging: {
    CLIENT_LOGO_ENDPOINT: 'https://d1a3el6wjv3s6k.cloudfront.net/',
    PASSPHRASE: 'a9k~g>4BD~vQC6.x'
  },
  test: {
    CLIENT_LOGO_ENDPOINT: 'https://d1a3el6wjv3s6k.cloudfront.net/',
    PASSPHRASE: 'a9k~g>4BD~vQC6.x'
  },
  production: {
    CLIENT_LOGO_ENDPOINT: 'https://d1a3el6wjv3s6k.cloudfront.net/',
    PASSPHRASE: 'a9k~g>4BD~vQC6.x'
  }
};

export default {
  ...environementConfig[environment],
  ...countryBasedEnvironmentConfig.production,
  API_SERVER,
  API_PATH_PREFIX: '/v3/agent',
  API_TIMEOUT: '1000',
  APP_ID: 'DataByte-F7-App',
  APP_NAME: 'DataByte',
  APP_THEME: 'md',
  APP_VERSION: '3.117.0',
  CLIENT_LOGO_EXTENSION: '.png',
  GROWL_AUTOHIDE: 5000,
  LOGOUT_THRESHOLD: 16 * 1000 * 60 * 60,
  WARN_THRESHOLD: 15 * 1000 * 60 * 60,
  ALLOW_CAMPAIGN_ID: '42',
  SMALL_DEVICE_WIDTH: 450,
  redirectToAgentV1
};
