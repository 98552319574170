import { call, delay, put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import Config from 'Config';
import { I18n } from 'Locales';
import * as Selectors from 'Selectors';
import { NavigationService } from 'Services';
import { APP_MODES, COUNTRIES } from 'Constants';
import { Types as GrowlTypes } from 'Reducers/growl';
import { user as userSelector } from 'Reducers/user';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { Types as CampaignTypes } from 'Reducers/campaign';
import { CheckIn, Flow, Result, User } from 'Repositories';
import { Types as PostCodesTypes } from 'Reducers/postCodes';
import { generateComponentProperties, stringToBool } from 'Helpers';
import { transient as transientSelector, Types as TransientTypes } from 'Reducers/transient';
import { offlineResultsImages as offlineResultsImagesSelector, Types as ResultTypes } from 'Reducers/result';
import {
  Types as ApplicationTypes,
  isOffline as isOfflineSelector,
  mode as modeSelector,
  lastTransaction as lastTransactionSelector
} from 'Reducers/application';
import {
  Types as FlowTypes,
  availablePages as availablePagesSelector,
  currentFlow as currentFlowSelector,
  currentFlowData as currentFlowDataSelector,
  currentFlowComponentLogicRules as currentFlowComponentLogicRulesSelector,
  currentFlowPageLogicRules as currentFlowPageLogicRulesSelector,
  currentFlowValidations as currentFlowValidationsSelector,
  currentPageIndex as currentPageIndexSelector,
  flows as flowsSelector,
  flowPages as flowPagesSelector,
  flowSignature as flowSignatureSelector,
  timeStartedFlow as timeStartedFlowSelector,
  validatedPages as validatedPagesSelector
} from 'Reducers/flow';

const checkComponents = function* ({ id, components, currentFlowData }) {
  for (const { logic_rules, properties, component_key: targetKey } of components) {
    const hasLogicRules = logic_rules.length > 0;
    const required = generateComponentProperties(properties).required;
    let visible = true;

    if (hasLogicRules) {
      // component can have defined only 1 logic rule
      const {
        action,
        value,
        predicate,
        component: { component_key: source_key, fields }
      } = logic_rules[0];
      yield put({
        type: FlowTypes.REGISTER_LOGIC_RULE,
        logicType: 'component',
        id: source_key,
        action,
        predicate,
        value,
        componentId: targetKey,
        fieldId: fields[0].id,
        fieldName: fields[0].name,
        componentExtras: {
          required,
          pageId: id
        }
      });
      visible = predicateCondition(predicate, '', value) && action === 'show';
    }

    if (
      (required && visible && !(currentFlowData && currentFlowData[targetKey]?.value)) ||
      (currentFlowData && currentFlowData[targetKey]?.isValid === 'false')
    ) {
      yield put({
        type: FlowTypes.INVALIDATE_COMPONENT,
        pageId: id,
        componentKey: targetKey
      });

      yield put({
        type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
        key: targetKey,
        value: {
          isValid: false
        }
      });
    }
  }
};

export const checkOfflineFlows = function* () {
  const offlineFlows = yield select(Selectors.offlineFlows);
  const offlineResultsImages = yield select(offlineResultsImagesSelector);

  if (offlineFlows.length > 0) {
    for (const offlineFlow of offlineFlows) {
      yield call(tryExecutionToken.bind(null, offlineFlow));
    }
  }
  if (offlineResultsImages !== undefined) {
    for (const item of offlineResultsImages) {
      yield put({
        type: ResultTypes.UPLOAD_RESULT_PICTURE,
        data: {
          file: item.file,
          id: item.result_id
        }
      });
    }
  }
};

const generatePageLogicRules = function* ({ id: pageId, logic_rules }) {
  for (const { action, predicate, value, related, component } of logic_rules) {
    if (related && component) {
      const { id: fieldId } = related;
      const { id: componentId } = component;
      yield put({
        type: FlowTypes.REGISTER_LOGIC_RULE,
        logicType: 'page',
        id: pageId,
        action,
        predicate,
        value,
        componentId,
        fieldId
      });
    }
  }
};

const navigateFlow = function* ({ step }) {
  const currentPageIndex = yield select(currentPageIndexSelector);
  const currentFlow = yield select(currentFlowSelector);

  yield put({
    type: FlowTypes.UPDATE_PROPS,
    props: {
      currentPage: currentFlow.flow_pages[currentPageIndex + step],
      currentPageIndex: currentPageIndex + step,
      pageIsInvalidOnNext: false
    }
  });
};

const predicateCondition = (predicate, updatingValue, value) => {
  if (Array.isArray(updatingValue)) {
    updatingValue = updatingValue.join(' '); // checkboxes' value is array
  }

  switch (predicate) {
    case 'equal_to':
      return value === updatingValue;

    case 'not_equal_to':
      return value !== updatingValue;

    case 'contains':
      if (typeof updatingValue === 'number') {
        updatingValue = updatingValue.toString();
      }
      return updatingValue?.includes(value);

    case 'does_not_contain':
      if (typeof updatingValue === 'number') {
        updatingValue = updatingValue.toString();
      }
      return !updatingValue?.includes(value);

    case 'greater_than':
      return parseFloat(updatingValue) > parseFloat(value);

    case 'less_than':
      return parseFloat(updatingValue) < parseFloat(value);

    case 'greater_than_or_equal_to':
      return parseFloat(updatingValue) >= parseFloat(value);

    case 'less_than_or_equal_to':
      return parseFloat(updatingValue) <= parseFloat(value);
  }
};

const pageIdFilter = (page, pageId) => pageId !== page;

// EXPORTED
export const forceUpdatePageComponents = function* () {
  const currentFlow = yield select(currentFlowSelector);
  const currentFlowData = yield select(currentFlowDataSelector);
  const currentPageIndex = yield select(currentPageIndexSelector);
  if (currentFlow) {
    const currentPage = currentFlow.flow_pages[currentPageIndex];

    if (currentPage) {
      for (const { component_key } of currentPage.components) {
        if (currentFlowData[component_key]) {
          yield put({
            type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
            key: component_key,
            value: {
              ...currentFlowData[component_key],
              forceUpdate: currentFlowData[component_key].forceUpdate
                ? currentFlowData[component_key].forceUpdate + 1
                : 0
            }
          });
        }
      }
    }
  }
};

export const get = function* ({ id, checkInId }) {
  const applicationMode = yield select(modeSelector);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SET, applicationMode },
      fail: { type: FlowTypes.GET_ERROR, checkInId }
    },
    promise: Flow.get(id)
  });
};

export const getPublishedFlows = function* ({ payload, checkInId }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.GET_PUBLISHED_FLOWS_SUCCESS, payload, checkInId },
      fail: { type: CheckInTypes.CHECK_OUT_ERROR }
    },
    promise: Flow.getPublishedFlows()
  });
};

export const getPublishedFlowsSuccess = function* ({ payload, checkInId }) {
  const selectedCampaign = yield select(Selectors.selectedOfflineCampaign);
  const foundFlow = payload.find(flow => flow?.id === selectedCampaign?.published_flows[0]?.id);

  if (foundFlow?.id !== selectedCampaign?.published_flows[0]?.id) {
    yield put({
      type: CheckInTypes.CHECK_OUT,
      id: checkInId?.id
    });

    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        new_published_flow: true
      }
    });

    yield put({ type: CampaignTypes.GET });
  } else {
    yield put({ type: CheckInTypes.VERIFY_CHECK_IN, flowId: foundFlow?.id });

    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        latestAppVersion: foundFlow.agent_app_version
      }
    });
  }
};

export const getDonorFlow = function* ({ id }) {
  const applicationMode = yield select(modeSelector);
  // step 2 authentication for donor mode
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SET_DONOR_FLOW, applicationMode },
      fail: { type: ApplicationTypes.DONOR_LOGIN_ERROR }
    },
    promise: Flow.get(id)
  });
};

export const getError = function* ({ checkInId, error }) {
  if (error?.response?.status === 404) {
    yield put({
      type: CheckInTypes.CHECK_OUT,
      id: checkInId
    });
    yield put({ type: CampaignTypes.GET });
    yield put({ type: CheckInTypes.GET });
  } else {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.flow.get.title'),
      body: I18n.t('growl:error.flow.get.body'),
      kind: 'error'
    });
  }
};

export const getExecutionToken = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_FLOW },
      fail: { type: FlowTypes.SUBMIT_FLOW }
    },
    promise: User.getExecutionToken()
  });
};

export const initFlowMetadata = function* ({ id }) {
  const mode = yield select(modeSelector);
  const flows = yield select(flowsSelector);
  const currentFlowPageLogicRules = yield select(currentFlowPageLogicRulesSelector);
  const flowPageIds = [];
  const availablePages = [];
  const { flow_pages, result_data } = flows[id];

  for (const { id, components, logic_rules, page_type } of flow_pages) {
    if (page_type === 'regular') {
      flowPageIds.push(id);
      if (logic_rules.length > 0) {
        yield call(generatePageLogicRules, { id, logic_rules });
      }
      yield call(checkComponents, { id, components, currentFlowData: result_data });
      if (!currentFlowPageLogicRules[id]) {
        availablePages.push(id);
      }
    }
  }

  if (result_data) {
    // donor login, got data from BA
    const keys = Object.keys(result_data);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // can be deprecated after old agent is decomissioned
      const resultValue = result_data[key].value;
      if (!resultValue || (typeof resultValue === 'object' && keys && keys.length === 0)) {
        delete result_data[key];
      } else {
        if (result_data[key].component === 'confirmed_email' && typeof result_data[key].value === 'string') {
          result_data[key].value = {
            email: result_data[key].value,
            emailConfirmation: result_data[key].value
          };
        }

        yield put({
          type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
          key: key,
          value: { ...result_data[key] }
        });
      }
    }
  }

  yield put({
    type: FlowTypes.UPDATE_PROPS,
    props: {
      currentFlowIndex: id,
      currentPage: flow_pages[0],
      currentPageIndex: 0,
      flowPages: flowPageIds,
      availablePages: availablePages
    }
  });

  yield put({
    type: FlowTypes.RUN_PAGE_VALIDATIONS,
    action: mode === 'donor' ? { type: FlowTypes.NAVIGATE_TO_FIRST_INVALIDATED_PAGE } : null
  });
};

export const initSubmitFlow = function* ({ submittedByBa = false }) {
  const lastTransaction = yield select(lastTransactionSelector);

  if (submittedByBa) {
    yield put({
      type: ApplicationTypes.UPDATE_PROPS,
      props: { lastTransaction: { ...lastTransaction, submittedByBa } }
    });
  }
  yield call(getExecutionToken);
};

export const navigateToFirstInvalidatedPage = function* () {
  const availablePages = yield select(availablePagesSelector);
  const validatedPages = yield select(validatedPagesSelector);
  const flowPages = yield select(flowPagesSelector);
  const invalidatedPages = availablePages.filter(p => !validatedPages.includes(p));
  if (invalidatedPages.length > 0) {
    const nextInvalidPage = invalidatedPages[0];
    yield call(navigateFlow, { step: flowPages.indexOf(nextInvalidPage) });
  } else {
    yield call(navigateFlow, { step: flowPages.length });
  }
  document.querySelector('.page-current > .page-content').scrollTo({
    top: document.querySelector('.page-current > .page-content').scrollHeight,
    behavior: 'smooth'
  });
};

export const nextFlowPage = function* () {
  const currentPageIndex = yield select(currentPageIndexSelector);
  const availablePages = yield select(availablePagesSelector);
  const flowPages = yield select(flowPagesSelector);
  const validatedPages = yield select(validatedPagesSelector);
  let step = 1;

  while (!availablePages.includes(flowPages[currentPageIndex + step]) && currentPageIndex + step < flowPages.length) {
    step = step + 1;
  }

  if (validatedPages.includes(flowPages[currentPageIndex])) {
    yield call(navigateFlow, { step });
  } else {
    yield call(forceUpdatePageComponents);
    yield put({
      type: FlowTypes.UPDATE_PROP,
      key: 'pageIsInvalidOnNext',
      value: true
    });
  }
};

export const summaryFlowPage = function* () {
  const currentPageIndex = yield select(currentPageIndexSelector);
  const flowPages = yield select(flowPagesSelector);
  const step = flowPages.length - currentPageIndex;

  yield call(navigateFlow, { step });

  yield put({
    type: FlowTypes.RUN_PAGE_VALIDATIONS
  });
};

export const previousFlowPage = function* () {
  const currentPageIndex = yield select(currentPageIndexSelector);
  const availablePages = yield select(availablePagesSelector);
  const flowPages = yield select(flowPagesSelector);
  let step = -1;
  while (!availablePages.includes(flowPages[currentPageIndex + step]) && currentPageIndex - step > 0) {
    step = step - 1;
  }
  yield call(navigateFlow, { step });
};

export const runComponentLogicRules = function* ({ key, value: updatingValue = null }) {
  const { result_data } = yield select(currentFlowSelector);
  const currentFlowData = yield select(currentFlowDataSelector);

  const currentFlowComponentLogicRules = yield select(currentFlowComponentLogicRulesSelector);
  const mode = yield select(modeSelector);
  if (key && updatingValue) {
    yield delay(250);
    const foundLogicRules = currentFlowComponentLogicRules[key];
    if (foundLogicRules && foundLogicRules.length > 0) {
      for (const foundLogicRule of foundLogicRules) {
        const {
          action,
          componentId,
          predicate,
          value,
          componentExtras: { pageId, required }
        } = foundLogicRule;
        const uValue = updatingValue.isProduct ? updatingValue?.productValue : updatingValue?.value;
        if (
          (updatingValue?.isValid || updatingValue?.isValid === undefined) &&
          predicateCondition(predicate, uValue, value) &&
          action === 'show'
        ) {
          if (!currentFlowData[componentId]) {
            yield put({
              type: FlowTypes.SHOW_COMPONENT,
              id: componentId
            });
            if ((required && mode === 'agent') || (required && mode === 'donor' && !result_data[componentId]?.value)) {
              yield put({
                type: FlowTypes.INVALIDATE_COMPONENT,
                pageId,
                componentKey: componentId
              });
            }
          }
        } else {
          if (currentFlowData[componentId]) {
            yield put({
              type: FlowTypes.STRIP_COMPONENT,
              id: componentId
            });
            yield put({
              type: FlowTypes.VALIDATE_COMPONENT,
              pageId,
              componentKey: componentId
            });
          }
        }
      }
    }

    yield put({
      type: FlowTypes.RUN_PAGE_LOGIC_RULES,
      value: updatingValue
    });
  }
};

export const runPageLogicRules = function* ({ value: updatingValue = null }) {
  if (updatingValue) {
    yield delay(250);
    const flowPages = yield select(flowPagesSelector);
    const currentFlowPageLogicRules = yield select(currentFlowPageLogicRulesSelector);
    const originalAvailablePages = yield select(availablePagesSelector);
    let availablePages = [...originalAvailablePages];

    for (const page of flowPages) {
      if (currentFlowPageLogicRules[page]) {
        const { componentId, action, predicate, value } = currentFlowPageLogicRules[page];
        if (updatingValue?.id === componentId) {
          if (
            (updatingValue?.isValid || updatingValue?.isValid === undefined) &&
            predicateCondition(predicate, updatingValue?.value, value) &&
            action === 'show'
          ) {
            if (!availablePages.includes(page)) {
              availablePages.push(page);
            }
          } else {
            if (availablePages.includes(page)) {
              availablePages = availablePages.filter(pageIdFilter.bind(null, page));
            }
          }
        }
      }
    }

    if (originalAvailablePages.length !== availablePages.length) {
      const removedPages = originalAvailablePages.filter(page => !availablePages.includes(page));
      yield put({
        type: FlowTypes.STRIP_PAGES,
        pages: removedPages
      });
    }

    yield put({
      type: FlowTypes.UPDATE_PROP,
      key: 'availablePages',
      value: availablePages
    });

    yield put({
      type: FlowTypes.RUN_PAGE_VALIDATIONS
    });
  }
};

export const runPageValidations = function* ({ action }) {
  yield delay(250);
  const availablePages = yield select(availablePagesSelector);
  const currentFlowValidations = yield select(currentFlowValidationsSelector);
  for (const pageId of availablePages) {
    if (currentFlowValidations[pageId]?.length > 0) {
      yield put({
        type: FlowTypes.INVALIDATE_PAGE,
        pageId
      });
    } else {
      yield put({
        type: FlowTypes.VALIDATE_PAGE,
        pageId
      });
    }
  }
  if (action) {
    yield put(action);
  }
};

export const setDonorFlow = function* ({ payload: { country } }) {
  const user = yield select(userSelector);
  if (!['DE', 'IE', 'UK'].includes(country)) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: PostCodesTypes.SET_DONOR_ADDRESSES },
        fail: { type: ApplicationTypes.DONOR_LOGIN_ERROR }
      },
      promise: CheckIn.getAddress(user.selected_postcodes, country.toLowerCase())
    });
  }
};

export const saveFlowForOffline = function* ({ data }) {
  const user = yield select(userSelector);
  const isOffline = yield select(isOfflineSelector);
  const timeStartedFlow = yield select(timeStartedFlowSelector);
  const selectedCampaign = yield select(Selectors.selectedOfflineCampaign);

  const offlineResult = {
    type: 'results',
    id: data.session_id,
    current_time: new Date().toJSON(),
    offline_id: data.session_id,
    created_at: timeStartedFlow,
    submitted_at: data.submitted_at,
    state: 'offline',
    campaign: selectedCampaign.name,
    offlineData: data
  };

  yield put({
    type: ResultTypes.ADD_OFFLINE_RESULT,
    userId: user.id,
    offlineResult
  });

  // close modal if token expires and sendLinkToDonor modal is open
  if (!isOffline) {
    yield put({ type: TransientTypes.UPDATE_PROPS, props: { donorModal: false } });
  }

  NavigationService.navigate({
    name: 'ThankYou',
    lastTransId: offlineResult.id
  });
};

export const submitFlow = function* ({ error, payload }) {
  const currentFlowData = yield select(currentFlowDataSelector);
  const flowId = yield select(Selectors.flowId);
  const flows = yield select(flowsSelector);
  const currentCheckIn = yield select(Selectors.checkInObject);
  const selectedCampaignName = yield select(Selectors.selectedCampaignName);
  const user = yield select(userSelector);
  const flowSignature = yield select(flowSignatureSelector);
  const timeStartedFlow = yield select(timeStartedFlowSelector);
  const transient = yield select(transientSelector);
  const isOffline = yield select(isOfflineSelector);
  const mode = yield select(modeSelector);
  const tokenExpired = error?.response?.status === 401;
  const showSignature = flows[flowId]?.flow_signature?.showSignature;

  const donorMode = mode === APP_MODES.DONOR;

  const result_signature = {
    component: 'flowSignature',
    value: flowSignature?.base64,
    visible: true
  };

  const { isComplete, latitude, longitude, sending_option, sendToDonor, signed } = transient;
  const donorSubmitKey = sending_option === 'Email' ? 'donor_email_address' : 'donor_phone_number';

  const data = {
    published_flow_id: flowId,
    campaign_name: selectedCampaignName,
    user_id: user?.id,
    check_in_id: currentCheckIn?.id,
    data: { ...currentFlowData, result_signature },
    ...(!donorMode && {
      ba_app_version: Config.APP_VERSION,
      played_at: timeStartedFlow,
      submitted_at: Date.now(),
      ba_device: navigator.userAgent
    }),
    ...(donorMode && {
      donor_app_version: Config.APP_VERSION,
      donor_played_at: timeStartedFlow,
      donor_completed_at: Date.now(),
      donor_device: navigator.userAgent
    }),
    execution_token: payload?.execution_token,
    session_id: user?.id + '-' + timeStartedFlow,
    lat: latitude,
    long: longitude,
    [donorSubmitKey]: transient[donorSubmitKey],
    incomplete: !isComplete || !signed || (!showSignature && sendToDonor)
  };

  if (isOffline || tokenExpired) data.offline_id = `${user?.id}-${timeStartedFlow}`;

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_FLOW_SUCCESS },
      fail: { type: FlowTypes.SUBMIT_FLOW_ERROR, data }
    },
    promise: Result.submitFlow(data)
  });
};

export const submitFlowSuccess = function* ({ payload }) {
  yield put({ type: TransientTypes.UPDATE_PROP, key: 'donorModal', value: false });
  if (payload[0].donor_link_error) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.smsServicesFail.title'),
      body: I18n.t('growl:error.smsServicesFail.body'),
      kind: 'error'
    });
  }
  NavigationService.navigate({
    name: 'ThankYou',
    lastTransId: payload[0].id
  });

  yield put({
    type: FlowTypes.UPDATE_PROP,
    key: 'currentPageIndex',
    value: 0
  });

  yield put({
    type: FlowTypes.UPDATE_PROP,
    key: 'validatedPages',
    value: []
  });
};

export const submitFlowError = function* ({ data, error }) {
  const mode = yield select(modeSelector);
  const donorMode = mode === APP_MODES.DONOR;
  const tokenExpired = error?.response?.status === 401;

  if (tokenExpired) {
    yield put({ type: FlowTypes.SAVE_FLOW_FOR_OFFLINE, data });
    yield put({ type: ApplicationTypes.SESSION_EXPIRED_LOGOUT });
  } else if (donorMode) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.flow.submit.title'),
      body: I18n.t('growl:error.flow.submit.body'),
      kind: 'error'
    });
  } else {
    yield put({ type: FlowTypes.SAVE_FLOW_FOR_OFFLINE, data });
  }
};

export const submitOfflineFlow = function* ({ offlineFlow, payload, userId }) {
  const { offlineData } = offlineFlow;
  offlineData.execution_token = payload.execution_token;

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_OFFLINE_FLOW_SUCCESS, offlineFlow, userId },
      fail: {}
    },
    promise: Result.submitFlow(offlineData)
  });
};

export const submitOfflineFlowSuccess = function* ({ offlineFlow, userId }) {
  const { offlineData } = offlineFlow;
  const user = yield select(userSelector);
  const offlineResultsImages = yield select(offlineResultsImagesSelector);
  const matchedItem = offlineResultsImages.find(item => item.offline_id === offlineData.offline_id);

  yield put({
    type: ResultTypes.SPLICE_OFFLINE_FLOW_BY_ID,
    userId: userId ? userId : user.id,
    offlineFlow
  });

  if (matchedItem) {
    yield put({
      type: ResultTypes.UPLOAD_RESULT_PICTURE,
      data: {
        file: matchedItem.file,
        id: matchedItem.offline_id
      }
    });

    yield put({
      type: ResultTypes.DELETE_OFFLINE_PICTURES,
      userId: user.id,
      offlinePictureObject: matchedItem
    });
  }

  yield put({ type: ResultTypes.GET });
};

export const tryExecutionToken = function* (offlineFlow) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_OFFLINE_FLOW, offlineFlow }
    },
    promise: User.getExecutionToken()
  });
};

export const validateOnlineBankAccount = function* ({ pageId, component, updateValues }) {
  const isOffline = yield select(isOfflineSelector);
  const {
    value: { accountNumber, sortCode, registrationNumber }
  } = updateValues;
  const { country } = component;

  let promise;

  if (country === 'UK' && accountNumber?.length > 0 && sortCode?.length > 0) {
    promise = Flow.bankAccountCheckUK.bind(null, accountNumber, sortCode);
  } else if (['BE', 'DE', 'FR', 'IE'].includes(country) && registrationNumber?.length > 0) {
    const formattedRegistrationNumber = registrationNumber.replace(/\s/g, '');
    promise = Flow.bankAccountCheckFR.bind(null, formattedRegistrationNumber);
  }

  if (typeof promise === 'function' && !isOffline) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_SUCCESS, pageId, component, updateValues },
        fail: { type: FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_ERROR, pageId, component, updateValues }
      },
      promise: promise()
    });
  } else {
    //cannot validate online, but component is offline valid
    yield put({
      type: FlowTypes.VALIDATE_COMPONENT,
      pageId,
      componentKey: component.component_key
    });
  }
};

export const validateOnlineBankAccountSuccess = function* ({ component, pageId, payload }) {
  let type;
  let isValid;
  let partialValue = {};
  const SHOW_IN_SUMMARY = 'show_in_summary_page';

  const displayInSummary = stringToBool(component.properties.find(obj => obj.property === SHOW_IN_SUMMARY).value);

  if (component.country !== COUNTRIES.UK && payload.error_messages.length > 0) {
    isValid = false;
    partialValue = {
      validationMessage: payload.error_messages[0],
      validationStatus: 'INVALID'
    };
    type = FlowTypes.INVALIDATE_COMPONENT;
  } else if (payload.error) {
    isValid = false;
    partialValue = {
      validationMessage: payload.error,
      validationStatus: 'INVALID'
    };
    type = FlowTypes.INVALIDATE_COMPONENT;
  } else {
    isValid = true;
    partialValue = {
      validationStatus: 'VALID',
      validationMessage: '',
      bankName: payload.bank
    };

    if (['BE', 'DE', 'FR', 'IE'].includes(component.country)) {
      partialValue = { ...partialValue, bic: payload.bic, bankName: payload.bank };
    }
    type = FlowTypes.VALIDATE_COMPONENT;
  }

  yield put({
    type: FlowTypes.UPDATE_BANK_COMPONENT_FLOW_DATA,
    key: component.component_key,
    value: partialValue,
    isValid: isValid,
    showInSummaryPage: displayInSummary
  });

  yield put({
    type,
    pageId,
    componentKey: component.component_key
  });
};

export const validateOnlineBankAccountError = function* ({ component, error, pageId, updateValues }) {
  const componentValue = {
    ...updateValues,
    value: { ...updateValues.value, validationStatus: 'ERROR', validationMessage: 'Server Error' }
  };
  const tokenExpired = error?.response?.status === 401;

  if (tokenExpired) {
    //to let the user finish the flow if token is expired and component is valid
    yield put({
      type: FlowTypes.VALIDATE_COMPONENT,
      pageId,
      componentKey: component.component_key
    });
  } else {
    yield put({
      type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
      key: component.component_key,
      value: componentValue
    });

    yield put({
      type: FlowTypes.VALIDATE_COMPONENT,
      pageId,
      componentKey: component.component_key
    });
  }
};
